<template>
    <div class="amendments-container">
        <vs-alert active="true">
          Sie besitzen keine Zugriffsrechte für diesen Bereich. Wenden Sie sich bitte an ihren Portal-Administrator.
        </vs-alert>
    </div>
  </template>
  <script>
  import Vue from 'vue';
  export default Vue.extend({
    name: '401',
    computed: {
      users() {
        return this.$store.getters['user/getUsers']
      },
    },
  });

  </script>
